import React from "react"
import * as Icon from "react-feather"
import HubspotForm from "react-hubspot-form";
import ContactImage from "../../assets/images/male_contact.jpg"
import {Link} from "gatsby";

const ContactInfo = () => {
    return (

        <div className="contact-info-area ptb-80xxx pt-120-b-80">
            <div className="container">
                <div className="section-title">
                    {/*<span className="sub-title">Our Solutions</span>*/}
                    <h1>Contact Us</h1>
                    <div className="bar"/>
                    <p>Drop us a Message</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6 d-none d-lg-block">
                            <img src={ContactImage} alt="about" className="w-full" />
                    </div>
                    <div className="col-lg-6 text-center my-5">
                        <p className="my-5">Applying for a Job? <Link
                            to="/careers/"
                            className="active"
                            activeClassName="active"
                        >
                            Click Here
                        </Link></p>
                        <HubspotForm
                            portalId='23285786'
                            formId='a2385fe5-60dd-4a45-98d7-d21f625be553'
                            // onSubmit={() => console.log('Submit!')}
                            // onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Mail/>
                            </div>
                            <h3>Mail Here</h3>
                            <p>
                                <a href="mailto:contact@ghanshyamdigital.com">
                                    contact@ghanshyamdigital.com
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.MapPin/>
                            </div>
                            <h3>Visit Here</h3>
                            <p>
                                107, 1st Floor, 21st century business centre, Nr.World trade centre, Ring Road, Surat -
                                395003, Gujarat, IN
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Phone/>
                            </div>
                            <h3>Call Here</h3>
                            <p>
                                <a href="tel:+918799074563">+91 87990 74563</a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ContactInfo
